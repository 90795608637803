import { useState, useEffect, useRef } from "react";
import { Transition } from "@headlessui/react";
import { classNames } from "../Helper/classNames";
import SearchBar from "./SearchBar";
import Tooltip from "./Tooltip";
import IconComponent from "./IconComponent";
import ApplicationLogo from "./ApplicationLogo";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import Modal from "./Modal";
import axios from "axios";
import Spinner from "./Spinner";

import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { CheckIcon } from "@heroicons/react/24/outline";

const statusData = {
  Verified: { style: "bg-green-600 text-white" },
  "Not Verified": { style: "bg-red-600 text-white" },
  "In Progress": { style: "bg-zinc-500 text-white" },
  Expiring: { style: "bg-cyan-600 text-white" },
  Expired: { style: "bg-zinc-500 text-white" },
};

export default function EmployeeList({ apiKey }) {
  const [showWidget, setShowWidget] = useState(false);
  const [people, setPeople] = useState([]);
  const [filteredPeople, setFilteredPeople] = useState([]);
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);

  //console.log(process.env.REACT_APP_API_URL);

  useEffect(() => {
    setLoading(showWidget);
    axios
      .get(process.env.REACT_APP_API_URL + "api/employeelist", {
        headers: { "x-api-key": apiKey },
      })
      .then((response) => {
        if (response.data !== undefined && response.data !== null) {
          setPeople(response.data);
          setFilteredPeople(response.data);
          inputRef.current.focus();
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        //console.error("Error fetching data:", error.response.data.message);
      });
  }, [showWidget]);

  const handleSearch = (keyword) => {
    const searchFields = ["fname", "lname", "email", "role", "title"];

    const filtered =
      keyword == ""
        ? people
        : people.filter((item) => {
            // Convert each item to lowercase and check if any property includes the keyword
            return searchFields.some((value) =>
              String(item[value]).toLowerCase().includes(keyword.toLowerCase())
            );
          });

    setFilteredPeople(filtered);
  };

  return (
    <div className="min-h-screen min-w-screen relative">
      <Modal
        open={showWidget}
        setOpen={setShowWidget}
        closeButtonLabel="Close"
        maxWidth="7xl"
        className="bg-zinc-100"
        handleClose={false}
        loading={loading}
      >
        <div className="mt-12 mx-auto w-auto px-4 sm:px-6 lg:px-10">
          <div className="rounded-lg bg-white shadow p-6 ">
            <div className="flex items-center space-x-5 justify-between">
              <div className="w-full">
                <div className="flex justify-between space-x-5">
                  <div className="">
                    <h1 className="text-xl sm:text-2xl font-bold text-gray-900 mb-0">
                      Verified Employees
                    </h1>
                    <p className="font-medium text-gray-500 italic text-xs mt-0">
                      Changing the World of Work
                    </p>
                  </div>
                  <div className="shrink">
                    <a href="https://www.entuitivecompliance.com">
                      <ApplicationLogo className="mx-auto w-36 sm:w-44" />
                    </a>
                  </div>
                </div>
                <div className="flex w-full overflow-hidden">
                  <SearchBar onChange={handleSearch} forwardRef={inputRef} />
                </div>
              </div>
            </div>
          </div>
          <ul className="grid grid-cols-1 gap-6 lg:grid-cols-2 xl:grid-cols-3 mt-8">
            {filteredPeople.map((person) => (
              <li
                key={person.email}
                className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow relative"
              >
                <Tooltip content={person.title}>
                  <div className="flex w-full items-center justify-between space-x-6 p-6">
                    <div className="flex-1 truncate">
                      <div className="flex items-center space-x-3">
                        <h3 className="sm:truncate text-[12px] sm:text-[14px] font-medium text-gray-900">
                          {person.fname + " " + person.lname}
                        </h3>
                        <span
                          className={classNames(
                            statusData[person.status]["style"],
                            "items-center rounded-md px-2.5 py-0.5 text-xs font-medium capitalize collapse sm:visible"
                          )}
                        >
                          {person.status}
                        </span>
                      </div>
                      <span
                        className={classNames(
                          statusData[person.status]["style"],
                          "items-center rounded-md px-2.5 py-0.5 text-xs font-medium capitalize visible sm:hidden"
                        )}
                      >
                        {person.status}
                      </span>
                      <p className="mt-1 text-[12px] sm:text-[14px] text-gray-500">
                        {person.title}
                      </p>
                    </div>
                    {person.imageUrl == null || person.imageUrl == "" ? (
                      <UserCircleIcon className="flex-shrink-0 h-[70px] w-[70px] sm:h-[80px] sm:w-[80px] rounded-full text-gray-300" />
                    ) : (
                      <img
                        loading="lazy"
                        className="h-[60px] w-[60px] sm:h-[70px] sm:w-[70px] flex-shrink-0 rounded-full bg-gray-300"
                        src={person.imageUrl}
                        alt=""
                        decoding="async"
                      />
                    )}
                  </div>
                </Tooltip>

                <div className="px-4">
                  <ul role="list" className="divide-y divide-gray-100">
                    {person.licenses &&
                      person.licenses.map((license) => {
                        return (
                          <li
                            key={license.id}
                            className="relative flex justify-between gap-x-6 px-0 py-5 hover:bg-gray-50"
                          >
                            <div className="flex gap-x-2 sm:gap-x-4">
                              <div
                                className={classNames(
                                  "flex h-8 w-8 sm:h-10 sm:w-10 mt-1 flex-none items-center justify-center rounded-lg bg-cyan-600"
                                )}
                              >
                                <IconComponent icon={license.logo} />
                              </div>
                              <div className="min-w-0 flex-auto text-[10px] sm:text-xs">
                                <p className="font-semibold leading-4 sm:leading-6 text-gray-900">
                                  {license.fileUrl ? (
                                    <a href={license.fileUrl} download>
                                      <span className="absolute inset-x-0 -top-px bottom-0" />
                                      {license.complianceName}
                                    </a>
                                  ) : (
                                    <span>{license.complianceName}</span>
                                  )}
                                </p>
                                <p className="mt-1 max-w-[15em] sm:w-[30em] lg:w-[14em] leading-5 text-gray-500 truncate flex-wrap md:flex-nowrap ">
                                  {license.name}
                                </p>
                              </div>
                            </div>
                            <div className="flex items-center gap-x-4">
                              <div className="hidden sm:flex sm:flex-col sm:items-end text-[10px] sm:text-xs">
                                <p className="leading-6 text-gray-900 truncate">
                                  {license.expiry_date ? "Expires On" : ""}
                                  {license.approved_on ? "Approved On" : ""}
                                </p>
                                <p className="mt-1 leading-5 text-gray-500 truncate">
                                  {license.expiry_date} {license.approved_on}
                                </p>
                              </div>
                              <ChevronRightIcon
                                className="h-5 w-5 flex-none text-gray-400 collapse sm:visible"
                                style={{ opacity: license.fileUrl ? 1 : 0 }}
                                aria-hidden="true"
                              />
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Modal>

      <Spinner show={loading} />

      <Transition.Root show={!showWidget} className="relative">
        <button
          className="fixed bottom-0 left-0 m-auto z-20"
          onClick={() => {
            setShowWidget(true);
            handleSearch("");
          }}
        >
          <div
            className="min-w-0 m-10  -pt-4 px-3 pb-3 text-cyan-500 hover:-translate-y-1 hover:scale-110 hover:text-cyan-400 duration-300 rounded-lg border border-gray-300 bg-white shadow-sm focus-within:ring-2"
            style={{ color: "#06b6d4" }}
          >
            <div className="flex items-start gap-x-0">
              <CheckIcon
                className="h-10 flex-none stroke-[3px]"
                aria-hidden="true"
              />
              <p className="text-md font-bold mt-3 -ml-3">erified with</p>
            </div>
            <ApplicationLogo className="w-32 ml-6 -mt-1 mr-2" />
          </div>
        </button>
      </Transition.Root>
    </div>
  );
}

import {
  ChevronRightIcon,
  PencilSquareIcon,
  DocumentCheckIcon,
  DocumentTextIcon,
  ShieldCheckIcon,
  CurrencyDollarIcon,
  ScaleIcon,
  BuildingLibraryIcon,
} from "@heroicons/react/20/solid";

export default function IconComponent({ icon }) {
  const iconComponents = {
    ChevronRightIcon,
    PencilSquareIcon,
    DocumentCheckIcon,
    DocumentTextIcon,
    ShieldCheckIcon,
    CurrencyDollarIcon,
    ScaleIcon,
    BuildingLibraryIcon,
  };
  const IconDiv = iconComponents[icon];
  return (
    <IconDiv className="h-4 w-4 sm:h-6 sm:w-6 text-white" aria-hidden="true" />
  );
}
